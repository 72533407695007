import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';

interface SortingToggleProps {
  value: boolean
}

const arrowUp = css`
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
`;

const arrowDown = css`
  clip-path: polygon(100% 0, 0 0, 50% 100%);
`;

export const Container = styled('div')`
  box-sizing: border-box;
  margin: 1rem auto;
  width: 700px;

  display: flex;
  align-items: center;
  justify-content: end;

  margin: 24px 0;
  padding: 0 16px;
`;

export const SortingToggle = styled('span')<SortingToggleProps>`
  color: ${colors.blue};
  font-weight: bold;

  position: relative;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: -16px;
    display: block;
    width: 12px;
    height: 12px;
 
    margin: 2px 0;

    background: ${colors.blue};
    ${props => (props.value ? arrowUp : arrowDown)}

    transition: clip-path 0.1s;
  }
`;
