import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { WAITLIST_RESEND_INTAKE_FORM, WAITLIST_START_SHOPPING_WITH_EMAIL } from 'src/middleware/waitlist';

import { updateAppointmentData } from 'src/middleware/eye-exams';

import { useToast } from 'src/contexts/toast';
import { formatExamType } from 'src/utils/formatExamType';
import { useAppointmentsPagination } from 'src/hooks/useAppointmentsPagination';

import { APPOINTMENT } from 'src/constants';
import { formatPatientName } from 'src/utils/appointments';
import { Button } from '@warbyparker/retail-design-system';
import AppointmentCard from '../appointment-card';
import Paginate from '../../paginate';

import opticianIcon from '../../assets/icon-optician-category.svg';
import * as StyledComponents from './styles';
import { AppointmentsSorting } from '../appointments-sorting';
import NextAvailable from '../next-available';

const AppointmentList = ({
  data,
  pageCount,
  page,
  pageSize,
  onToggleAppointmentsView,
  filterButtonText,
  sortItems,
  updateAll,
  sort,
}) => {
  const { addToast } = useToast();

  const dispatch = useDispatch();

  const {
    goToPage,
    nextPage,
    previousPage,
    pageOptions,
  } = useAppointmentsPagination({ page, shouldRemovePagination: true, pageSize, pageCount, sort });

  const [selectedId, setSelectedId] = useState('');

  const onCardClick = useCallback((row) => {
    setSelectedId(selection => (selection === row.id ? '' : row.id));
  }, []);

  const onShopWithCustomer = useCallback((
    patientEmail: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
  ) => {
    dispatch({
      type: WAITLIST_START_SHOPPING_WITH_EMAIL,
      payload: { patientEmail, firstName, lastName, phoneNumber },
    });
    addToast({ title: 'Redirecting to Checkout...', type: 'info' });
  }, []);

  const checkInAppointment = useCallback((appointment) => {
    dispatch(updateAppointmentData(
      { ...appointment },
      { status: APPOINTMENT.STATUS.CHECKED_IN },
      sort,
    ));
  }, []);

  const arrivedAppointment = useCallback((appointment) => {
    dispatch(updateAppointmentData(
      { ...appointment },
      { status: APPOINTMENT.STATUS.ARRIVED },
      sort));
  }, []);

  const noShowAppointment = useCallback((appointment) => {
    dispatch(updateAppointmentData(
      { ...appointment },
      { status: APPOINTMENT.STATUS.NO_SHOW },
      sort));
  }, []);

  const handleResend = (appointmentId) => {
    dispatch({ type: WAITLIST_RESEND_INTAKE_FORM, payload: appointmentId });
    addToast({ title: 'Resending intake form...', type: 'info' });
  };

  return (
    <>
      <NextAvailable />
      <StyledComponents.FiltersContainer>
        <Button onClick={onToggleAppointmentsView}>
          {filterButtonText}
        </Button>
      </StyledComponents.FiltersContainer>
      <AppointmentsSorting items={sortItems} updateAll={updateAll} />
      {!data.length
        ? (
          <StyledComponents.EmptyListMessage>
            — No booked exams for next two hours —
          </StyledComponents.EmptyListMessage>
        )
        : data
          .map((item) => {
            const { startAt, patient = {} } = item || {};
            const formattedTime = new Date(startAt).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });

            const patientName = formatPatientName(patient);

            return (
              <StyledComponents.PaddingSetter key={item.id}>
                <StyledComponents.Container onClick={() => onCardClick(item)}>
                  <StyledComponents.SummaryColumn>
                    <StyledComponents.IconColumn>
                      <StyledComponents.CategoriesIcon icon={opticianIcon} />
                    </StyledComponents.IconColumn>

                    <StyledComponents.DetailColumn>
                      <StyledComponents.LabelBold>
                        {patientName}
                      </StyledComponents.LabelBold>

                      {formatExamType(item.config?.options || ['NOT_SPECIFIED'])}
                    </StyledComponents.DetailColumn>

                    <StyledComponents.TimeColumn>
                      <h5>{formattedTime}</h5>
                    </StyledComponents.TimeColumn>
                  </StyledComponents.SummaryColumn>

                  {item.id === selectedId && (
                  <AppointmentCard
                    handleResend={handleResend}
                    handleCheckIn={checkInAppointment}
                    handleArrived={arrivedAppointment}
                    handleShop={onShopWithCustomer}
                    handleNoShow={noShowAppointment}
                    row={item}
                    sort={sort}
                  />
                  )}
                </StyledComponents.Container>
              </StyledComponents.PaddingSetter>
            );
          })}
      {pageCount !== null && page !== null && (
        <StyledComponents.PaddingSetterPaginate>
          <Paginate
            currentPage={page}
            pageCount={pageCount}
            goToPage={goToPage}
            previousPage={previousPage}
            nextPage={nextPage}
            pageOptions={pageOptions}
          />
        </StyledComponents.PaddingSetterPaginate>
      )}
    </>
  );
};

export default AppointmentList;
