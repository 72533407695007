import React from 'react';
import styled from '@emotion/styled';

import { WAITLIST } from 'src/constants';

import selectedBubble from './status-bubble-selected.svg';
import unselectedBubble from './status-bubble-unselected.svg';

const StatusHeader = styled('div')`
  align-items: center;
  background: #f8f8f8;
  display: flex;
  height: 118px;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 2;
  top: 78px;
  justify-content: center;
`;

const StatusTab = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 118px;
  margin: 0 14px;
  width: 180px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const BubbleBrow = styled('div')`
  border: ${(props) => (props.selected ? '1.5px solid #0089bf' : '1.5px solid #f8f8f8')};
  background: ${(props) => (props.selected ? '#0089bf' : '#f8f8f8')};
  border-radius: 1.5px;
  width: 30px;
  height: 0px;
  margin-bottom: 21px;
`;

const Bubble = styled('div')`
  background: url(${(props) => (props.selected ? selectedBubble : unselectedBubble)}) no-repeat;
  width: 36px;
  height: 36px;
  font-weight: 600;
  font-size: ${(props) => (props.count >= 100 ? '14px;' : '20px')};
  color: ${(props) => (props.selected ? '#ffffff;' : '#676F78')};
  text-align: center;
  line-height: 36px;
`;

const StatusText = styled('div')`
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  padding-top: 16px;
`;

const StatusTabs = ({
  appointments,
  canAccessExamsTab,
  positions,
  selectedStatus,
  handleSelectStatus,
}) => {
  const positionCounts = {
    waiting: 0,
    in_progress: 0,
    complete: 0,
  };
  (positions || []).forEach(position => {
    if (Object.keys(positionCounts).includes(position.status)) {
      positionCounts[position.status] += 1;
    }
  });

  return (
    <StatusHeader>
      {canAccessExamsTab && (
      <StatusTab
        selected={selectedStatus === WAITLIST.STATUS.EYE_EXAMS}
        onClick={() => handleSelectStatus(WAITLIST.STATUS.EYE_EXAMS)}
      >
        <BubbleBrow selected={selectedStatus === WAITLIST.STATUS.EYE_EXAMS} />
        <Bubble count={appointments.length} selected={selectedStatus === 'eye_exams'}>
          {appointments.length}
        </Bubble>
        <StatusText>Exams</StatusText>
      </StatusTab>
      )}
      <StatusTab
        selected={selectedStatus === WAITLIST.STATUS.WAITING}
        onClick={() => handleSelectStatus(WAITLIST.STATUS.WAITING)}
      >
        <BubbleBrow selected={selectedStatus === WAITLIST.STATUS.WAITING} />
        <Bubble count={positionCounts.waiting} selected={selectedStatus === 'waiting'}>
          {positionCounts.waiting}
        </Bubble>
        <StatusText>Waiting</StatusText>
      </StatusTab>

      <StatusTab
        selected={selectedStatus === WAITLIST.STATUS.IN_PROGRESS}
        onClick={() => handleSelectStatus(WAITLIST.STATUS.IN_PROGRESS)}
      >
        <BubbleBrow selected={selectedStatus === WAITLIST.STATUS.IN_PROGRESS} />
        <Bubble count={positionCounts.in_progress} selected={selectedStatus === 'in_progress'}>
          {positionCounts.in_progress}
        </Bubble>
        <StatusText>In Progress</StatusText>
      </StatusTab>

      <StatusTab
        selected={selectedStatus === WAITLIST.STATUS.COMPLETE}
        onClick={() => handleSelectStatus(WAITLIST.STATUS.COMPLETE)}
      >
        <BubbleBrow selected={selectedStatus === WAITLIST.STATUS.COMPLETE} />
        <Bubble count={positionCounts.complete} selected={selectedStatus === 'complete'}>
          {positionCounts.complete}
        </Bubble>
        <StatusText>Complete</StatusText>
      </StatusTab>
    </StatusHeader>
  );
};

export default StatusTabs;
