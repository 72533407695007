import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReviewIcon, ShopIcon } from '@warbyparker/retail-design-system';
import * as Styled from './styles';

import { CMS_PAGES } from '../../../constants';
import { requestAppointmentModificationAction } from '../../../middleware/eye-exams/actions';
import FeatureFlagged from '../../feature-flagged';
import { MODIFY_EYE_EXAM_BOOKING_FEATURE_FLAG } from '../../../../feature-flags';
import { OVERFLOW_BUTTON_HEIGHT_PX } from './constants';
import { calculateMenuHeightInPixels, calculateMenuTopPosition } from './utils';
import OverflowPage, { OverflowPageRef } from '../../overflow-page';

const AppointmentOverflowMenu = ({ disabled = false, handleNoShow, appointment }) => {
  // This state tells if the menu should be visible or not
  const [showMenu, setShowMenu] = useState<boolean>(false);

  // This state tells if the menu should appear above or below the row
  const [showMenuAbove, setShowMenuAbove] = useState<boolean>(false);
  const overflowPageRef = useRef<OverflowPageRef | null>(null);

  const menuHeightPx = calculateMenuHeightInPixels();
  const isIOSApp = !!window.IS_HERMES;
  const baseUri = `${process.env.WARBY_BOOKING_URL}/${CMS_PAGES.MODIFY_APPOINTMENT_PAGE}`;
  const url = new URL(baseUri);
  url.searchParams.set('appointmentId', appointment && appointment.id);

  const dispatch = useDispatch();

  const openMenu = (e) => {
    // Menu should appear above the row if the menu height
    // is greater than the space below the overflow menu button
    const spaceBelow = window.innerHeight
      - e.target.getBoundingClientRect().bottom
      - 104 // The height of the footer
      + (OVERFLOW_BUTTON_HEIGHT_PX / 2);

    setShowMenuAbove(menuHeightPx > spaceBelow);
    setShowMenu(true);
    e.stopPropagation();
  };

  const closeMenu = (e) => {
    setShowMenu(false);
    e.stopPropagation();
  };

  const onModifyAppointment = () => {
    if (isIOSApp) {
      dispatch(requestAppointmentModificationAction({ modifyAppointmentUrl: url.toString() }));
      return;
    }

    if (!overflowPageRef.current) return;

    overflowPageRef.current.openPage();
  };

  const onNoShow = (e) => {
    handleNoShow(appointment);
    closeMenu(e);
  };

  const menuTopPx = calculateMenuTopPosition(menuHeightPx, showMenuAbove);

  return (
    <Styled.OverflowContainer>
      <Styled.OverflowButton
        data-testid="overflow-button"
        disabled={disabled}
        onClick={openMenu}
      >
        &middot;&middot;&middot;
      </Styled.OverflowButton>
      {
        showMenu && (
          <>
            <Styled.Overlay onClick={closeMenu} />
            <Styled.Menu menuTopPx={menuTopPx}>
              <FeatureFlagged feature={MODIFY_EYE_EXAM_BOOKING_FEATURE_FLAG}>
                <Styled.MenuItem
                  disabled={(!appointment || !appointment.id)}
                  onClick={onModifyAppointment}
                >
                  <ReviewIcon />
                  <Styled.MenuItemText>
                    Modify Appointment
                  </Styled.MenuItemText>
                </Styled.MenuItem>
              </FeatureFlagged>
              <Styled.MenuItem
                onClick={onNoShow}
              >
                <ShopIcon />
                <Styled.MenuItemText>
                  No show
                </Styled.MenuItemText>
              </Styled.MenuItem>
            </Styled.Menu>
          </>
        )
      }
      <OverflowPage
        title="Modify Appointment"
        src={url.toString()}
        ref={overflowPageRef}
      />
    </Styled.OverflowContainer>
  );
};

export default AppointmentOverflowMenu;
