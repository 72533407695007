import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';

export const CardListContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 700px;
  margin: 20px auto 0;
`;

export const CardList = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  margin: 0px auto;
`;

export const CardListButton = styled.button`
  margin: 0px;
  cursor: pointer;
  border: 0px;
  background: transparent;
`;

export const CardTileContainer = styled.div<{ active?: boolean }>`
  width: 230px;
  height: 100px;
  background-color: ${colors.white};
  border: 1px solid ${colors.grayBorder};
  transform: scale(0.9);
  transition: transform 0.3s ease;
  padding: 10px;
  border-radius: 6px;
`;

export const LabelBold = styled('p')`
  color: #6c6f71;
  font-weight: 900;
`;
