import React from 'react';
import { findNextEarliestDate } from 'src/utils/formatDate';
import { APPOINTMENT_TYPES_NAMES } from 'src/constants';
import { CardTileContainer, LabelBold } from './styles';

export const CardTile = ({ data, isActive, facility: { short_name } }) => (
  <CardTileContainer active={isActive}>
    <LabelBold>{APPOINTMENT_TYPES_NAMES[data.serviceName]}</LabelBold>
    {findNextEarliestDate(
      data.appointments[short_name]
        ? data.appointments[short_name].perDate : {},
    )}
  </CardTileContainer>
);
