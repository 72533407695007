import styled from '@emotion/styled';
import { Button } from '@warbyparker/retail-design-system';
import { colors } from 'src/styles/colors';

type IconType = 'cancel' | 'confirm';

interface ButtonProps {
  hasCheckedIn?: boolean;
}

interface IconProps {
  type: IconType
}

const iconTypes: Record<IconType, string> = {
  cancel: 'polygon(90% 0, 100% 10%, 60% 50%, 100% 90%, 90% 100%, 50% 60%, 10% 100%, 0 90%, 40% 50%, 0 10%, 10% 0, 50% 40%)',
  confirm: 'polygon(35% 65%, 90% 10%, 100% 25%, 35% 90%, 0% 50%, 14% 40%)',
};

export const Container = styled('div')`
  margin-left: 15%;
`;

export const CustomContainer = styled('div')<ButtonProps>`
  width: 50%;
`;

export const DetailsContainer = styled('div')<ButtonProps>`
  display: flex;
`;

export const Buttons = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: right;
  height: 60px;
  width: 95%;

  margin-left: 8px;
  margin-top: 16px;
`;

export const LabelBold = styled('span')`
  color: #6c6f71;
  font-weight: 900;
  margin-right: 3px;
`;

export const NotesContainer = styled('div')`
  margin-top: 16px;
  margin-right: 44px;

  display: flex;
  align-items: flex-start;
`;

export const NotesFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: 100%;
  margin-right: 1rem;
`;

export const NoteDetails = styled.span`
  max-height: 5rem;
  overflow-y: auto;

  width: 100%;

  margin-left: 4px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NotesField = styled.textarea`
  border: 1px solid ${colors.grayChateau};
  color: ${colors.tundora};

  border-radius: 4px;
  padding: 0.5rem;

  resize: none;
  width: 100%;
  overflow-y: auto;

  margin-left: 4px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CustomButton = styled(Button)`
  height: 45px;
  margin: 0px 5px;
`;

export const IconButton = styled.span<IconProps>`
  width: 24px;
  height: 24px;

  background: ${props => (props.type === 'cancel' ? colors.white : colors.darkBlue)};

  border: ${props => (props.type === 'cancel' ? `1px solid ${colors.darkBlue}` : 0)};

  border-radius: 99999px;

  padding: 0.5rem;
  margin-right: 1.20rem;

  display: grid;
  place-content: center;

  &:hover {
    background: ${props => (props.type === 'cancel' ? colors.silver : colors.blue)};
  }

  &::before {
    content: '';
    display: block;

    width: 16px;
    height: 16px;


    background-color: ${props => (props.type === 'cancel'
    ? colors.darkBlue : colors.white)};

    clip-path: ${props => (props.type in iconTypes
    ? iconTypes[props.type]
    : 'none')};
  }
`;

export const ParagraphContainer = styled('p')`
  margin: 0;

  & + & {
    margin-top: 8px;
  }
`;
