import { EyeExamState } from './types';

export const initialState: EyeExamState = {
  appointments: {
    data: [],
    count: 0,
    total: 0,
    page: 1,
    pageCount: 1,
  },
  facility: null,
  nextAvailables: [],
  loading: {
    appointments: false,
    appointmentUpdates: false,
    facility: false,
    noShowIds: [],
  },
  errors: {
    appointments: '',
    appointmentUpdates: '',
    facility: '',
  },
};
