import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { NextAvailable } from 'src/middleware/eye-exams/eye-exam-types';
import {
  CaretRight12Icon,
  CaretLeft12Icon,
} from '@warbyparker/retail-design-system';
import { Facility } from 'src/types/auth';
import { CardList, CardListButton, CardListContainer } from './styles';
import { CardTile } from './card-item';

interface NextAvailableProps {
  nextAvailables: NextAvailable[];
  facility: Facility;
}

const NextAvailableComponentCard = ({
  nextAvailables,
  facility,
}: NextAvailableProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  if (!nextAvailables.length) {
    return null;
  }

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleScrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 210;
    }
  };

  const handleScrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 210;
    }
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      if (currentIndex > 0 && currentIndex < nextAvailables.length - 2) {
        handleScrollLeft();
      }
    }
  };

  const handleNextClick = () => {
    if (currentIndex < nextAvailables.length - 1) {
      setCurrentIndex(currentIndex + 1);
      if (currentIndex > 0 && currentIndex < nextAvailables.length - 1) {
        handleScrollRight();
      }
    }
  };

  return (
    <CardListContainer>
      {nextAvailables.length > 3 && (
        <CardListButton onClick={handlePrevClick} disabled={currentIndex === 0}>
          <CaretLeft12Icon />
        </CardListButton>
      )}

      <CardList ref={containerRef}>
        {nextAvailables.map((card, index) => (
          <CardTile
            key={`key-${card.serviceName}`}
            data={card}
            isActive={index === currentIndex}
            facility={facility}
          />
        ))}
      </CardList>

      {nextAvailables.length > 3 && (
        <CardListButton
          onClick={handleNextClick}
          disabled={currentIndex === nextAvailables.length - 1}
        >
          <CaretRight12Icon />
        </CardListButton>
      )}
    </CardListContainer>
  );
};

const select = (state) => ({
  nextAvailables: state.eyeExams.nextAvailables,
  facility: state.auth.me.facility,
});

export default connect(select)(NextAvailableComponentCard);
