import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import {
  CLEAR_SEND_RETURN_SMS_ERROR,
  SEND_RETURN_SMS,
} from 'src/middleware/waitlist/index';

import exclamationIcon from './icon-exclamation.svg';

import CustomerItem from './customer-item';
import AppointmentList from './appointments/appointment-list';
import { Spinner } from './spinner';

const Container = styled('div')`
  display: inherit;
  justify-content: flex-start;
  align-items: center;
  margin: 16px auto 0;
  max-width: 700px;
`;

const EmptyListMessage = styled('p')`
  color: #a1a4a6;
  font-size: 20px;
  margin: 218px auto 0;
  max-width: 768px;
  text-align: center;
`;

const ErrorContainer = styled('div')`
  align-items: center;
  background: rgba(214, 0, 60, 0.1);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  margin: 42px auto 24px;
  width: 704px;
`;

const ErrorIcon = styled('span')`
  background: url(${exclamationIcon}) no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 15px;
  margin: 0 15px 0;
  width: 15px;
`;

const ErrorPara = styled('p')`
  color: #e47c4c;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 18px 0;
  text-align: center;
`;

const ListContainer = styled('div')`
  margin: 197px auto 0;
  padding: 0 0 12px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
`;

const CustomerList = ({
  dispatch,
  appointments,
  isAppointmentsLoading,
  positions,
  sendSMSError,
  updateStatusError,
  startShoppingError,
  selectedStatus,
  sortItems,
  updateAll,
  sort,
  showFilteredAppointments,
  setShowFilteredAppointments,
  filteredAppointments,
}) => {
  const [expandedPositionId, setExpandedPositionId] = useState(null);

  const isEyeExamTab = useMemo(() => selectedStatus.includes('eye_exams'), [selectedStatus]);
  const listError = useMemo(
    () => sendSMSError || updateStatusError || startShoppingError,
    [sendSMSError, updateStatusError, startShoppingError],
  );

  const handleSMSClick = async (id) => {
    if (sendSMSError) {
      // clear out stale data
      await dispatch({ type: CLEAR_SEND_RETURN_SMS_ERROR });
    }
    await dispatch({
      type: SEND_RETURN_SMS,
      payload: { waitlist_position_id: id },
    });
  };

  if (!positions.length && !isEyeExamTab) {
    let statusText = 'No visitors waiting';
    if (selectedStatus === 'in_progress') statusText = 'No visitors in progress';
    if (selectedStatus === 'complete') statusText = 'No completed visits';

    return (
      <>
        <EmptyListMessage>{`— ${statusText} —`}</EmptyListMessage>
      </>
    );
  } if (isEyeExamTab && !isAppointmentsLoading && !appointments.data.length) {
    return (
      <>
        <EmptyListMessage>— No booked exams —</EmptyListMessage>
      </>
    );
  }

  const positionStatus = ['waiting', 'in_progress', 'complete'];

  const toggleAppointmentsView = () => {
    setShowFilteredAppointments(!showFilteredAppointments);
  };

  const filterButtonText = showFilteredAppointments ? 'Show All' : 'Show Upcoming';

  return (
    <>
      <ListContainer>
        {listError && (
          <ErrorContainer>
            <ErrorIcon />
            <ErrorPara>{listError}</ErrorPara>
          </ErrorContainer>
        )}
        {positionStatus.includes(selectedStatus)
          && positions
            .sort((a, b) => new Date(a.created) - new Date(b.created))
            .map((position) => (
              <CustomerItem
                expanded={expandedPositionId === position.id}
                setExpandedPositionId={setExpandedPositionId}
                handleSMSClick={handleSMSClick}
                key={position.id}
                position={position}
                selectedStatus={selectedStatus}
                updateStatusError={updateStatusError}
              />
            ))}
        {isEyeExamTab && (
          isAppointmentsLoading
            ? <Spinner />
            : (
              <Container>
                <AppointmentList
                  data={showFilteredAppointments ? filteredAppointments : appointments.data}
                  pageCount={showFilteredAppointments ? null : appointments.pageCount}
                  page={showFilteredAppointments ? null : appointments.page}
                  sortItems={sortItems}
                  updateAll={updateAll}
                  onToggleAppointmentsView={toggleAppointmentsView}
                  hasFilteredAppointments={filteredAppointments.length}
                  filterButtonText={filterButtonText}
                  sort={sort}
                />
              </Container>
            )
        )}
      </ListContainer>
    </>
  );
};

export default CustomerList;
