import styled from '@emotion/styled';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { APPOINTMENTS_SORT_INITIAL, CMS_PAGES, EYE_EXAM, INITIAL_PAGE, WAITLIST } from 'src/constants';

import {
  DISABLE_AUTO_REFRESH,
  dispatchFetchFacilityDisplayAddress,
  ENABLE_AUTO_REFRESH,
  FETCH_WAITLIST,
  FETCH_WAITLIST_FACILITY_TEAM,
  REQUEST_QR_FOR_WAITLIST,
  SET_SELECTED_STATUS,
} from 'src/middleware/waitlist/index';
import { filterAppointmentsByTime } from 'src/utils/customerList';

import useSnowplowContext from 'src/hooks/useSnowplowContext';

import { fetchAppointmentsList, fetchFacilityEyeExamServices } from 'src/middleware/eye-exams';

import { withModal } from 'src/modal';

import { Button } from '@warbyparker/retail-design-system';
import { CUSTOMER_QR_CODES, AUTO_REFRESH_FEATURE_FLAG } from 'feature-flags';
import toCheckFeature from 'src/connect-helpers/toCheckFeature';
import { useToast } from 'src/contexts/toast';
import { useListSort } from 'src/hooks/useListSort';
import AddAssigneeModal from './add-assignee-modal';
import CustomerList from './customer-list';
import HeaderNav from './header-nav';
import RemoveCustomerModal from './remove-customer-modal';
import { QRCodeScanner } from './scanner';
import StatusTabs from './status-tabs';
import OverflowPage from './overflow-page';

const Page = styled('div')`
  background: #f8f8f8;
  height: 100%;
  left: 0;
  min-height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
`;

const Content = styled('div')`
  scroll-behavior: smooth;
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 104px);
  -webkit-overflow-scrolling: touch;
`;

const Footer = styled('div')`
  align-items: center;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: start;
  position: fixed;
  width: 100%;
  height: 104px;
  box-sizing: border-box;
  padding: 0 30px;

  & button:not(:first-of-type) {
    margin-left: 15px;
  }
`;

const Waitlist = ({
  dispatch,
  appointments,
  isLoadingPositions,
  isLoadingAppointments,
  isLoadingAppointmentUpdates,
  facility,
  facilityDisplayAddress,
  facilityDisplayAddressError,
  facilityEyeExamServices,
  facility_team,
  modal,
  positions,
  selectedStatus,
  sendSMSError,
  updateStatusError,
  user,
  startShoppingError,
  enableQRCodeScan,
  autoRefreshFeatureEnabled,
}) => {
  const { addToast } = useToast();
  const history = useHistory();
  const { eventTracker } = useSnowplowContext();
  const { items: sortItems, sort, replaceAll: updateAll } = useListSort(
    ...APPOINTMENTS_SORT_INITIAL,
  );
  const PAGE_SIZE = 50;

  const isRemoveModal = window.location.pathname.includes('remove-customer');
  const isAddAssigneeModal = window.location.pathname.includes('add-assignee');
  const contentRef = useRef(null);
  const overflowPageRef = useRef(null);

  const parsedQuery = queryString.parse(window.location.search);
  const redirect = parsedQuery.next;
  const { redirectToExams } = parsedQuery;

  const [showFilteredAppointments, setShowFilteredAppointments] = useState(true);

  const canAccessExamsTab = useMemo(() => (
    facilityEyeExamServices
      && facilityEyeExamServices?.attributes?.eyeExamProviderType
        !== EYE_EXAM.PROVIDER_TYPE.NO_EXAMS
      && appointments.data && appointments.data.length > 0
  ), [facilityEyeExamServices, appointments]);

  const openBookEyeExam = useCallback(() => {
    eventTracker?.trackNewExamBookingEvent({
      advisorId: user.id,
      createdAt: new Date(),
      defaultFacilityId: facility?.id,
    });

    if (!overflowPageRef.current) return;

    addToast({
      title: 'Redirected to Booking Exam Page!',
    });

    overflowPageRef.current.openPage();
  }, [eventTracker, user.id, facility?.id, overflowPageRef]);

  const selectExamsTab = () => {
    dispatch({
      type: SET_SELECTED_STATUS,
      payload: { selected_status: WAITLIST.STATUS.EYE_EXAMS },
    });
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      dispatch({ type: DISABLE_AUTO_REFRESH });
    } else {
      dispatch({ type: ENABLE_AUTO_REFRESH });
    }
  };

  const handleSelectStatus = useCallback(status => {
    dispatch({
      type: SET_SELECTED_STATUS,
      payload: { selected_status: status },
    });
  }, []);

  useEffect(() => {
    if (!isRemoveModal && !isAddAssigneeModal) {
      dispatch({ type: FETCH_WAITLIST });
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { opticians, sales_advisors } = facility_team;
    if (isAddAssigneeModal && (!sales_advisors.length && !opticians.length)) {
      dispatch({ type: FETCH_WAITLIST_FACILITY_TEAM });
    }
  }, []);

  useEffect(() => {
    if (!isRemoveModal && !isAddAssigneeModal) {
      dispatch(fetchAppointmentsList({
        page: INITIAL_PAGE,
        pageSize: PAGE_SIZE,
        idNotIn: null,
        sort,
      }));
    }
  }, [sort]);

  useEffect(() => {
    if (sendSMSError || updateStatusError || startShoppingError) {
      contentRef.current.scrollTop = 0;
    }
  }, [sendSMSError, updateStatusError, startShoppingError]);

  useEffect(() => {
    if (autoRefreshFeatureEnabled) {
      dispatch({ type: ENABLE_AUTO_REFRESH });
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }
    dispatch(dispatchFetchFacilityDisplayAddress());
    dispatch(fetchFacilityEyeExamServices());

    return () => {
      if (autoRefreshFeatureEnabled) {
        dispatch({ type: DISABLE_AUTO_REFRESH });
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }
    };
  }, []);

  useEffect(() => {
    if (canAccessExamsTab && redirectToExams) {
      selectExamsTab();
    }
  }, [canAccessExamsTab]);

  const Modal = modal || (() => null);

  const filteredPositions = positions.filter(p => p.status === selectedStatus);

  const bookEyeExamURL = useMemo(() => {
    const baseUrl = `${process.env.WARBY_BOOKING_URL}/${CMS_PAGES.EYE_EXAM_BOOKING}`;
    const canBookEyeExam = facilityEyeExamServices
      && facilityEyeExamServices?.attributes?.eyeExamProviderType
      !== EYE_EXAM.PROVIDER_TYPE.NO_EXAMS;

    if (
      facilityDisplayAddressError
      || !facilityDisplayAddress
      || !canBookEyeExam) {
      return `${baseUrl}`;
    }

    const {
      city_slug: citySlug,
      location_slug: locationSlug,
    } = facilityDisplayAddress;

    return `${baseUrl}/${citySlug}/${locationSlug}`;
  }, [facilityDisplayAddress, facilityDisplayAddressError, facilityEyeExamServices]);

  const filteredAppointments = filterAppointmentsByTime(appointments.data);

  const handleRefresh = () => {
    dispatch({ type: FETCH_WAITLIST });
    dispatch(fetchAppointmentsList({
      page: appointments.page || INITIAL_PAGE,
      pageSize: PAGE_SIZE,
      idNotIn: null,
      sort,
    }));
  };

  return (
    <>
      <Page>
        <HeaderNav
          loading={isLoadingPositions || isLoadingAppointments}
          facilityName={facility.name}
          onRefresh={handleRefresh}
          positions={positions}
          showRefresh
          backUrl={redirect}
        />
        <StatusTabs
          appointments={showFilteredAppointments ? filteredAppointments : appointments.data}
          canAccessExamsTab={canAccessExamsTab}
          positions={positions}
          selectedStatus={selectedStatus}
          handleSelectStatus={handleSelectStatus}
        />
        <Modal />
        <Content ref={contentRef} hasCustomers={!!filteredPositions.length}>
          <CustomerList
            dispatch={dispatch}
            appointments={appointments}
            isAppointmentsLoading={isLoadingAppointments || isLoadingAppointmentUpdates}
            positions={filteredPositions}
            sendSMSError={sendSMSError}
            updateStatusError={updateStatusError}
            startShoppingError={startShoppingError}
            selectedStatus={selectedStatus}
            sortItems={sortItems}
            updateAll={updateAll}
            sort={sort}
            showFilteredAppointments={showFilteredAppointments}
            setShowFilteredAppointments={setShowFilteredAppointments}
            filteredAppointments={filteredAppointments}
          />
        </Content>
        <Footer>
          <Button
            onClick={() => history.push('/add-customer')}
            size="large"
          >
            Add visitor
          </Button>
          <Button
            onClick={openBookEyeExam}
            size="large"
          >
            Book eye exam
          </Button>
          {enableQRCodeScan ? (
            <QRCodeScanner
              onClick={() => dispatch({ type: REQUEST_QR_FOR_WAITLIST })}
            />
          ) : null}
        </Footer>
      </Page>
      {!facilityDisplayAddressError && (
      <OverflowPage
        title="Eye Exam Booking"
        src={bookEyeExamURL}
        ref={overflowPageRef}
      />
      )}
    </>
  );
};

const select = (state) => ({
  user: state.auth.me.user,
  facility: state.auth.me.facility,
  facilityDisplayAddress: state.waitlist.facility_display_address,
  facilityDisplayAddressError: state.waitlist.error.facility_display_address,
  facilityEyeExamServices: state.eyeExams.facility,
  positions: state.waitlist.positions,
  appointments: state.eyeExams.appointments,
  isLoadingPositions: state.waitlist.loading,
  isLoadingAppointments: state.eyeExams.loading.appointments,
  isLoadingAppointmentUpdates: state.eyeExams.loading.appointmentUpdates,
  selectedStatus: state.waitlist.selected_status,
  sendSMSError: state.waitlist.error.send_sms,
  updateStatusError: state.waitlist.error.update_status,
  startShoppingError: state.waitlist.error.start_shopping,
  facility_team: state.waitlist.facility_team,
  enableQRCodeScan: window.IS_HERMES && toCheckFeature(state)(CUSTOMER_QR_CODES),
  autoRefreshFeatureEnabled: toCheckFeature(state)(AUTO_REFRESH_FEATURE_FLAG),
});

const WaitlistPage = connect(select)(Waitlist);
export default WaitlistPage;

export const AddAssigneeModalPage = withModal(WaitlistPage, AddAssigneeModal);
export const RemoveCustomerModalPage = withModal(WaitlistPage, RemoveCustomerModal);
