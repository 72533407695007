import styled from '@emotion/styled';

interface IconProps {
  icon?: string;
}

export const PaddingSetter = styled('div')`
  background: white !important;
  color: #a1a4a6;
  font-size: 20px;
  margin: 16px auto 0;
  max-width: 700px;
  max-height: 400px;
  text-align: center;
  border: 1px solid #e4e6e8;
  border-radius: 8px;
`;

export const PaddingSetterPaginate = styled('div')`
  background: transparent;
  color: #a1a4a6;
  font-size: 20px;
  margin: 20px auto 0;
  max-width: 700px;
  max-height: 400px;
  text-align: center;`;

export const Container = styled('div')`
  width: 700px;
  max-height: 396px;
  box-sizing: border-box;
  justify-content: space-between;
  cursor: pointer;
  text-align: left;
  @media (max-width: 744px) {
    width: 690px;
  }
`;

export const FiltersContainer = styled('div')`
  margin: 1rem auto;
  width: 700px;

  & > button {
    & + button {
      margin-left: 0.5rem;
    }
  }
`;

export const SummaryColumn = styled('div')`
  width: auto;
  display: flex;
`;

export const DetailColumn = styled('span')`
  width: 70%;
  height: 100px;
`;

export const IconColumn = styled('span')`
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimeColumn = styled('span')`
  width: 15%;

  & > h5 {
    text-align: center;
  }
`;

export const LabelBold = styled('p')`
  color: #6c6f71;
  font-weight: 900;
`;

export const CategoriesIcon = styled.span<IconProps>`
  background: ${(props) =>
    (props.icon ? `url(${props.icon}) no-repeat center` : 'none')};
  background-size: 100%;
  display: inline-block;
  width: 48px;
  height: 48px;
  object-fit: contain;
`;

export const EmptyListMessage = styled('p')`
  color: #a1a4a6;
  font-size: 20px;
  margin: 218px auto 0;
  max-width: 768px;
  text-align: center;
`;
