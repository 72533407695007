import { useCallback, useMemo, useState } from 'react';
import { clampNum } from 'src/utils/clampNum';

type SortOrder = 'asc' | 'desc';
export type SortItem = { column: string; label: string; order: SortOrder; active: boolean };
export type SortItemUpdate = Pick<SortItem, 'column'> & Partial<SortItem>;

export type UpdateItemFn = (params: SortItemUpdate) => void;
export type UpdatePositionFn = (params: SortItem & {
  newIndex?: number | undefined
}) => void;
export type UpdateToggleFn = (params: SortItem) => void;
export type UpdateAllFn = (...items: SortItem[]) => void;

export const useListSort = (...initialItems: SortItem[]) => {
  const [items, setItems] = useState<SortItem[]>(initialItems);

  const updateItem = useCallback<UpdateItemFn>(item => {
    setItems(allItems => allItems.map(sortItem => (
      sortItem.column !== item.column ? sortItem : ({ ...sortItem, ...item })),
    ));
  }, []);

  const updatePosition = useCallback<UpdatePositionFn>(({
    active,
    column,
    label,
    order,
    newIndex = 0,
  }) => {
    setItems(allItems => {
      const currentItems = Array.from(allItems.filter(item => !(column === item.column)));

      if (!order) return currentItems;

      const itemIndex = clampNum(newIndex, 0, allItems.length - 1);

      currentItems.splice(itemIndex, 0, { active, column, label, order });

      return currentItems;
    });
  }, []);

  const updateToggle = useCallback<UpdateToggleFn>(item => {
    setItems(allItems => allItems.map(sortItem => ({
      ...sortItem,
      active: item.column === sortItem.column ? item.active : sortItem.active,
    })));
  }, []);

  const replaceAll = useCallback<UpdateAllFn>((...itemsToReplace) => {
    setItems(itemsToReplace);
  }, []);

  const sort = useMemo(
    () => items.filter(item => item.active).map(item => `${item.column}:${item.order}`),
    [items],
  );

  return {
    items,
    sort,
    updateItem,
    updatePosition,
    updateToggle,
    replaceAll,
  };
};
