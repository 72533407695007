import React from 'react';
import { SortItem, UpdateAllFn, useListSort } from 'src/hooks/useListSort';
import { Container, SortingToggle } from './styles';

type AppointmentsSortingProps = {
  items: SortItem[]
  updateAll: UpdateAllFn
};

export const AppointmentsSorting: React.FC<AppointmentsSortingProps> = ({
  items,
  updateAll,
}) => {
  const { items: changes, updateItem } = useListSort(...items);

  const toggleOrder = React.useCallback((column: string) => {
    const statusItem = changes.find(item => item.column === column);

    if (!statusItem) return;

    updateItem({
      column,
      order: statusItem.order === 'asc' ? 'desc' : 'asc',
    });
  }, [changes]);

  React.useEffect(() => {
    if (items.every(item => changes.includes(item))) return;

    updateAll(...changes);
  }, [changes]);

  const startAtItem = React.useMemo(() => (
    changes.find(item => item.column === 'startAt')!
  ), [changes]);

  /**
   * Created like this because Asc/Desc ordering for appointment times are
   * different from the logical one we do (asc/desc on numbers).
   */
  const invertedOrders = {
    asc: 'Descending',
    desc: 'Ascending',
  };

  return (
    <Container>
      <SortingToggle
        onClick={() => toggleOrder('startAt')}
        role="button"
        value={startAtItem.order === 'desc'}
      >
        Sort by time:
        {' '}
        {invertedOrders[startAtItem.order]}
      </SortingToggle>
    </Container>
  );
};
