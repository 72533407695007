import { MENU_ITEM_HEIGHT_PX, OVERFLOW_BUTTON_HEIGHT_PX, SPACER_HEIGHT_PX } from './constants';

export const calculateMenuHeightInPixels = (): number => {
  // There are always at least 5 items in the menu.
  // There can be up to 8 in some circumstances.
  const items = 1;
  return items * MENU_ITEM_HEIGHT_PX + SPACER_HEIGHT_PX;
};

export const calculateMenuTopPosition = (menuHeightPx: number, showMenuAbove: boolean): number => {
  // If "top" is not specified, the menu would appear
  // below the bottom of the overflow button.
  // We want to move it up some pixels based on whether we
  // are showing it above or below the overflow button.
  if (showMenuAbove) {
    return -(menuHeightPx + OVERFLOW_BUTTON_HEIGHT_PX / 2);
  }
  return (-OVERFLOW_BUTTON_HEIGHT_PX / 2);
};
