import { put, takeLatest, select } from 'redux-saga/effects';
import { APPOINTMENT, APPOINTMENT_TYPES } from 'src/constants';
import API from 'src/service-clients/helios-retail';
import { createScheduledSet } from 'src/utils/createScheduledSet';
import { formatDate } from 'src/utils/formatDate';
import type {
  Appointment,
  AppointmentListResponse,
} from 'src/service-clients/pmp-api-types';
import { initialState } from './state';
import type { ExamServicesPayload, NextAvailable } from './eye-exam-types';
import { EyeExamState, FetchAppointmentsListPayload } from './types';
import {
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENTS_FAILURE,
  FETCH_APPOINTMENTS_SUCCESS,
  FETCH_FACILITY_EXAM_SERVICES,
  FETCH_FACILITY_EXAM_SERVICES_FAILURE,
  FETCH_FACILITY_EXAM_SERVICES_SUCCESS,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_FAILURE,
  UPDATE_APPOINTMENT_SUCCESS,
} from './actions';

const idsCache = createScheduledSet(5000);

const updateAppointmentOnData = (data: Appointment[], appt: Appointment | null) =>
  (appt ? data.map(a => (a.id === appt.id ? { ...a, ...appt } : a)) : data);

export const fetchAppointmentsList = (params: FetchAppointmentsListPayload =
{ page: null, pageSize: null, idNotIn: null, update: null, sort: null }) => ({
  type: FETCH_APPOINTMENTS,
  payload: {
    page: params.page,
    pageSize: params.pageSize,
    idNotIn: params.idNotIn,
    update: params.update,
    sort: params.sort,
  },
});

export const fetchFacilityEyeExamServices = () => ({
  type: FETCH_FACILITY_EXAM_SERVICES,
});

export const updateAppointmentData = (
  appointment: Appointment,
  update: Partial<Appointment>,
  sort: string[],
) => ({
  type: UPDATE_APPOINTMENT,
  payload: { appointment, update, sort },
});

const fetchAppointmentsListSuccess = (data: AppointmentListResponse) => ({
  type: FETCH_APPOINTMENTS_SUCCESS,
  payload: data,
});

const fetchAppointmentsListFailure = (error: string) => ({
  type: FETCH_APPOINTMENTS_FAILURE,
  payload: error,
});

const fetchFacilityEyeExamServicesSuccess = (data: ExamServicesPayload) => ({
  type: FETCH_FACILITY_EXAM_SERVICES_SUCCESS,
  payload: data,
});

const fetchFacilityEyeExamServicesFailure = (error: string) => ({
  type: FETCH_FACILITY_EXAM_SERVICES_FAILURE,
  payload: error,
});

const updateAppointmentDataSuccess = (appointment: Appointment) => ({
  type: UPDATE_APPOINTMENT_SUCCESS,
  payload: appointment,
});

export const updateAppointmentDataFailure = (error: string) => ({
  type: UPDATE_APPOINTMENT_FAILURE,
  payload: error,
});

function* fetchAppointments(action: ReturnType<typeof fetchAppointmentsList>) {
  const [token, facilityShortName] = yield select((state) => [
    state.auth.token,
    state.auth.me.facility.short_name,
  ]);

  const statusToFilter = [
    APPOINTMENT.STATUS.ARRIVED,
    APPOINTMENT.STATUS.BOOKED,
    APPOINTMENT.STATUS.CHECKED_IN,
    APPOINTMENT.STATUS.CHECKED_IN_ONLINE,
    APPOINTMENT.STATUS.COMPLETE,
    APPOINTMENT.STATUS.CONFIRMED,
    APPOINTMENT.STATUS.IN_ROOM,
    APPOINTMENT.STATUS.IN_SESSION,
  ];

  const { idNotIn, update, sort, ...params } = { ...action.payload };

  const todayRef = Date.now();
  const today = formatDate(new Date(todayRef));
  const tomorrow = formatDate(new Date(todayRef + 86_400_000));

  try {
    const result = yield API.getAppointmentList({
      token,
      facilityShortName,
      status: statusToFilter.join(','),
      idNotIn: [idNotIn, ...idsCache.toArray()].filter(e => e !== null).join(','),
      doctorBreak: false,
      startFrom: today,
      startThru: tomorrow,
      sort: (sort || []).join(','),
      ...params,
    });

    idsCache.add(idNotIn);

    result.data = updateAppointmentOnData(result.data, update ?? null);

    yield put(fetchAppointmentsListSuccess(result));
  } catch (err) {
    yield put(fetchAppointmentsListFailure((err as Error).message));
  }
}

function* fetchFacilityExamServices() {
  const [token, facilityId, facilityShortNames] = yield select((state) => [
    state.auth.token,
    state.auth.me.facility.id,
    state.auth.me.facility.short_name,
  ]);

  try {
    const result = yield API.getFacilityEyeExamServices({ token, facilityId });

    let services: NextAvailable[] = [];
    if (result) {
      const uniqueConfigOptions = [
        APPOINTMENT_TYPES.GLASSES_ONLY,
        APPOINTMENT_TYPES.GLASSES_CL_CURWEAR,
        APPOINTMENT_TYPES.GLASSES_CL_NEWWEAR,
      ];
      const todayRef = Date.now();
      const date = new Date(todayRef);
      const fromDate = date.toISOString().split('T')[0];

      // Add 14 days to the current date
      const limitDate = new Date(date);
      limitDate.setDate(date.getDate() + 14);
      const thruDate = limitDate.toISOString().split('T')[0];

      // Don't like this approach but is the only way so far
      // cause if we send appointmentTypes as array the response doesn't contain the config type
      // and we need to know the availables appintments by config types
      services = yield Promise.all(
        uniqueConfigOptions.map(async (serviceName) => {
          try {
            const appointments = await API.getNextAppointmentAvailable({
              token,
              facilityShortNames,
              fromDate,
              appointmentTypes: serviceName,
              thruDate,
            });
            return { serviceName, appointments };
          } catch {
            return { serviceName, appointments: [] };
          }
        }),
      );
    }

    yield put(
      fetchFacilityEyeExamServicesSuccess({
        examServices: result,
        nextAvailables: services,
      }),
    );
  } catch (err) {
    yield put(fetchFacilityEyeExamServicesFailure((err as Error).message));
  }
}

function* updateAppointment({
  payload: { appointment, update, sort },
} : ReturnType<typeof updateAppointmentData>) {
  const [token, page] = yield select((state) => [
    state.auth.token,
    state.eyeExams.appointments.page,
    state.auth?.me?.features,
  ]);

  const PAGE_SIZE = 50;

  try {
    yield API.updateAppointment({
      token,
      appointment: { ...appointment, ...update },
    });

    const idsToHide: string[] = [];

    if ('status' in update && update.status === APPOINTMENT.STATUS.NO_SHOW) {
      idsToHide.push(appointment.id);
    }

    yield put(fetchAppointmentsList({
      page,
      pageSize: PAGE_SIZE,
      idNotIn: idsToHide,
      update: { ...appointment, ...update },
      sort,
    }));

    yield put(updateAppointmentDataSuccess({ ...appointment, ...update }));
  } catch (err) {
    yield put(updateAppointmentDataFailure((err as Error).message));
  }
}

const reducer = (state = initialState, { type, payload }): EyeExamState => {
  switch (type) {
    case UPDATE_APPOINTMENT:
      return {
        ...state,
        loading: { ...state.loading, appointments: true },
        errors: { ...state.errors, appointmentUpdates: initialState.errors.appointmentUpdates },
      };
    case FETCH_APPOINTMENTS:
      return {
        ...state,
        appointments: initialState.appointments,
        loading: { ...state.loading, appointments: true },
        errors: { ...state.errors, appointments: initialState.errors.appointments },
      };
    case FETCH_FACILITY_EXAM_SERVICES:
      return {
        ...state,
        facility: null,
        loading: { ...state.loading, facility: true },
        errors: { ...state.errors, facility: initialState.errors.facility },
      };
    case FETCH_FACILITY_EXAM_SERVICES_SUCCESS:
      return {
        ...state,
        facility: payload.examServices,
        nextAvailables: payload.nextAvailables,
        errors: { ...state.errors, facility: initialState.errors.facility },
      };
    case FETCH_FACILITY_EXAM_SERVICES_FAILURE:
      return {
        ...state,
        facility: initialState.facility,
        errors: { ...state.errors, facility: payload },
      };
    case UPDATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          appointmentUpdates: initialState.loading.appointmentUpdates,
        },
      };
    case UPDATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, appointments: initialState.loading.appointmentUpdates },
        errors: { ...state.errors, appointmentUpdates: payload },
      };
    case FETCH_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: payload,
        loading: { ...state.loading, appointments: initialState.loading.appointments },
        errors: { ...state.errors, appointments: initialState.errors.appointments },
      };
    case FETCH_APPOINTMENTS_FAILURE:
      return {
        ...state,
        appointments: initialState.appointments,
        loading: { ...state.loading, appointments: initialState.loading.appointments },
        errors: { ...state.errors, appointments: payload },
      };
    default:
      return state;
  }
};

function* saga() {
  yield takeLatest(FETCH_APPOINTMENTS, fetchAppointments);
  yield takeLatest(FETCH_FACILITY_EXAM_SERVICES, fetchFacilityExamServices);
  yield takeLatest(UPDATE_APPOINTMENT, updateAppointment);
}

export default {
  reducer,
  saga,
};
