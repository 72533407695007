import { SortItem } from './hooks/useListSort';
import { Schemas } from './types/snowplow-events';

export const SnowplowSchemas: Schemas = {
  events: {
    newExamBookingEvent: 'iglu:com.warbyparker/new_exam_booking_from_waitlist_event/jsonschema/2-0-0',
    bookedCustomerShopEvent: 'iglu:com.warbyparker/booked_customer_in_waitlist/jsonschema/2-0-0',
  },
};

export const MAX_NUM_OF_PAGINATION_BUTTONS = 7;
export const INITIAL_PAGE = 1;
export const INITIAL_PAGE_SIZE = 10;

export const AUTO_REFRESH_RATE_MS = 15000;

export const APPOINTMENT = Object.freeze({
  STATUS: Object.freeze({
    ARRIVED: 'Arrived',
    BOOKED: 'Booked',
    CANCELLED: 'Cancelled',
    CHECKED_IN: 'CheckedIn',
    CHECKED_IN_ONLINE: 'CheckedInOnline',
    COMPLETE: 'Complete',
    CONFIRMED: 'Confirmed',
    IN_ROOM: 'InRoom',
    IN_SESSION: 'InSession',
    NO_SHOW: 'NoShow',
    RESCHEDULED: 'Rescheduled',
  }),
});

export const EYE_EXAM = Object.freeze({
  PROVIDER_TYPE: Object.freeze({
    NO_EXAMS: 'NoExams',
  }),
});

export const WAITLIST = Object.freeze({
  STATUS: Object.freeze({
    EYE_EXAMS: 'eye_exams',
    WAITING: 'waiting',
    IN_PROGRESS: 'in_progress',
    COMPLETE: 'complete',
  }),
});

export const APPOINTMENT_TYPES = Object.freeze({
  GLASSES_ONLY: 'GLASSES_ONLY',
  GLASSES_CL_CURWEAR: 'GLASSES_CL_CURWEAR',
  GLASSES_CL_NEWWEAR: 'GLASSES_CL_NEWWEAR',
});

export const CMS_PAGES = Object.freeze({
  MODIFY_APPOINTMENT_PAGE: 'internal/retail/appointments/eye-exams/edit',
  EYE_EXAM_BOOKING: 'appointments/eye-exams/booking',
});

export const APPOINTMENT_TYPES_NAMES = Object.freeze({
  GLASSES_ONLY: 'Glasses Only',
  GLASSES_CL_CURWEAR: 'Glasses & CL Current Wearer',
  GLASSES_CL_NEWWEAR: 'Glasses & CL New Wearer',
});

export const APPOINTMENTS_SORT_INITIAL: SortItem[] = [
  {
    active: true,
    column: 'startAt',
    label: 'Order by Service Timetable',
    order: 'asc',
  },
  {
    active: true,
    column: 'status',
    label: 'Order by Status Label',
    order: 'asc',
  },
];

export enum OpticianCategoryKeys {
  Adjustment = 'adjustment',
  RxTroubleshoot = 'rx_troubleshoot',
  PdMeasurement = 'pd_measurement',
  OcMeasurement = 'oc_measurement',
  CpuAdjustment = 'cpu_adjustment',
  CpuNoAdjustment = 'cpu_no_adjustment',
  SegMeasurement = 'seg_measurement',
  Neutralization = 'neutralization',
  LensReplacement = 'lens_replacement',
}

export enum ShoppingCategoryKeys {
  NonRx = 'non_rx',
  Optical = 'optical',
  RxOnHand = 'rx_on_hand',
  Insurance = 'insurance',
  SingleVision = 'single_vision',
  Sun = 'sun',
  NeedRx = 'needs_rx',
  Fsa = 'fsa',
  Progressive = 'progressive',
  Contacts = 'contacts',
  EyeExam = 'eye_exam',
  ReturnOrExchange = 'return_or_exchange',
}

export const TIME_SENSITIVE_CATEGORY_KEYS: string[] = [
  OpticianCategoryKeys.PdMeasurement,
  OpticianCategoryKeys.OcMeasurement,
  OpticianCategoryKeys.SegMeasurement,
];
