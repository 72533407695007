import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from '@emotion/styled';

import { UPDATE_CUSTOMER_STATUS } from 'src/middleware/waitlist';
import { Paper } from '@warbyparker/retail-design-system';

import { getDefaultCategories } from './customer-form-default-categories';

import SendSMSButton from './send-sms-button';
import PositionOverflowMenu from './position-overflow-menu';
import shoppingIcon from './assets/icon-shopping-category.svg';
import opticianIcon from './assets/icon-optician-category.svg';
import shoppingOpticianIcon from './icon-shopping-optician-category.svg';
import { CustomerItemButton } from '../shared-components/buttons';
import Tag, { hasTimeSensitiveCategory } from './tag';

const CustomerContainer = styled(Paper)`
  display: flex;
  margin: 12px auto;
  width: 706px;
  padding: 0 0 24px 0;
`;

const CategoriesIcon = styled('span')`
  background: ${props => (props.icon ? `url(${props.icon}) no-repeat center` : 'none')};
  background-size: 100 %;
  display: inline-block;
  height: 48px;
  width: 84px;
  margin: 34px 0 0;
`;

const InfoContainer = styled('div')`
  flex: 1;
`;

const TopInfo = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 24px 0 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

const Name = styled('p')`
  margin: 0;
`;

const PartySize = styled('span')`
  color: #676F78;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
`;

const WaitTime = styled('p')`
  margin: 0 24px 0 0;
  min-width: 80px;
  text-align: right;
`;

const Assignee = styled('p')`
  margin: 6px 0 0;
  font-size: 18px;
  height: 24px;
  line-height: 24px;
`;

const CompactCategories = styled('p')`
  width: 600px;
  margin: 6px 0 0;
  font-size: 18px;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Categories = styled('p')`
  width: 600px;
  margin: 6px 0 0;
  font-size: 18px;
  line-height: 24px;
  white-space: normal;
  word-wrap: break-word;
`;

const PhoneAndCheckedIn = styled('div')`
  width: 100%;
  display: flex;
  margin: 24px 0 0;
  font-size: 18px;
  line-height: 28px;
`;

const InfoValue = styled('p')`
  margin: 0 34px 0 0;
`;

const InfoLabel = styled('span')`
  font-weight: 800;
  padding: 0 6px 0 0;
`;

const Notes = styled('div')`
  width: 100%;
  font-size: 18px;
  line-height: 28px;
`;

const Buttons = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin: 24px 24px 0 auto;
`;

const TagAndWaitTime = styled.div`
  gap: 20px;
  display: flex;
`;

export const hasNoSubcategories = (category) => typeof category === 'boolean';

export const isCategorySelected = (category) => {
  if (hasNoSubcategories(category)) {
    return category;
  }

  // if it is a category with nested subcategories,
  // check if there are any subcategories that are
  // selected
  return Object.values(category).some(subcat => subcat);
};

export const getSubcategoryDisplayName = (categoryName, subcategory) => {
  // in the case of one category having subcategories
  // but the other being set to the default case of false
  if (hasNoSubcategories(subcategory)) return '';

  const defaultCategories = getDefaultCategories();
  const category = defaultCategories[categoryName];

  const subcategoryDisplayNames = category.reduce((name, cat) => {
    const { key } = cat;
    if (key in subcategory && subcategory[key] === true) {
      const { displayName, subtext } = cat;
      const subcatName = subtext ? `${displayName} ${subtext}` : displayName;
      return [...name, subcatName];
    }

    return name;
  }, []);

  return subcategoryDisplayNames.join(' / ');
};

export const assigneeFormatter = (
  categories,
  optician_assigned = false,
  sales_advisor_assigned = false,
) => {
  if (!categories) return '';
  const { optician = false, shopping = false } = categories;

  // format categories with assignee
  const hasAssignee = optician_assigned || sales_advisor_assigned;
  const isOptician = isCategorySelected(optician);
  const isShopping = isCategorySelected(shopping);
  if (hasAssignee && (!isShopping && !isOptician)) return 'Paired';
  if (hasAssignee && (isShopping && !isOptician)) return 'Shopping';
  if (hasAssignee && (!isShopping && isOptician)) return 'Working';
  if (hasAssignee && (isShopping && isOptician)) return 'Paired';

  return '';
};

export const categoriesFormatter = (categories) => {
  if (!categories) return '';
  const { optician = false, shopping = false } = categories;

  // format categories with no subcategory
  const isOpticianNoSubcategory = hasNoSubcategories(optician);
  const isShoppingNoSubcategory = hasNoSubcategories(shopping);
  if ((isShoppingNoSubcategory && shopping) && (isOpticianNoSubcategory && optician)) {
    return 'Shopping / Optician';
  }
  if (isShoppingNoSubcategory && shopping) return 'Shopping';
  if (isOpticianNoSubcategory && optician) return 'Optician';
  if ((isShoppingNoSubcategory && !shopping) && (isOpticianNoSubcategory && !optician)) {
    return '';
  }

  // format categories with subcategories
  const isOptician = isCategorySelected(optician);
  const isShopping = isCategorySelected(shopping);
  const opticianSubcategoryNames = getSubcategoryDisplayName('optician', optician);
  const shoppingSubcategoryNames = getSubcategoryDisplayName('shopping', shopping);
  if (isShopping && isOptician) return `${shoppingSubcategoryNames} / ${opticianSubcategoryNames}`;
  if (isShopping) return shoppingSubcategoryNames;
  if (isOptician) return opticianSubcategoryNames;

  // default case
  return '';
};

const categoriesIconSelector = (categories) => {
  if (!categories) return <CategoriesIcon icon={null} />;
  const { optician = false, shopping = false } = categories;

  // format icon for categories with no subcategories
  const isOpticianNoSubcategory = hasNoSubcategories(optician);
  const isShoppingNoSubcategory = hasNoSubcategories(shopping);
  if ((isShoppingNoSubcategory && shopping) && (isOpticianNoSubcategory && optician)) {
    return <CategoriesIcon icon={shoppingOpticianIcon} />;
  }
  if (isOpticianNoSubcategory && optician) return <CategoriesIcon icon={opticianIcon} />;
  if (isShoppingNoSubcategory && shopping) return <CategoriesIcon icon={shoppingIcon} />;
  if ((isShoppingNoSubcategory && !shopping) && (isOpticianNoSubcategory && !optician)) {
    return <CategoriesIcon icon={null} />;
  }

  // format icon for categories with subcategories
  const isOpticianWithSubcat = isCategorySelected(optician);
  const isShoppingWithSubcat = isCategorySelected(shopping);
  if (isOpticianWithSubcat && isShoppingWithSubcat) {
    return <CategoriesIcon icon={shoppingOpticianIcon} />;
  }
  if (isOpticianWithSubcat) return <CategoriesIcon icon={opticianIcon} />;
  if (isShoppingWithSubcat) return <CategoriesIcon icon={shoppingIcon} />;

  return <CategoriesIcon icon={null} />;
};

const customerNameFormatter = (name) => {
  if (!name) return 'No name given';

  const spaceIdx = name.indexOf(' ');
  // return name if there is no first and last,
  // only first OR last
  if (spaceIdx === -1) return name;

  const firstName = name.slice(0, spaceIdx);
  const lastName = name.slice(spaceIdx + 1);
  return name.length > 11 ? `${firstName} ${lastName.slice(0, 1)}` : name;
};

const waitTimeFormatter = (created) => {
  const currentTime = moment(new Date());
  const customerAddedTime = moment(new Date(created));
  const timeDifference = moment.duration(currentTime.diff(customerAddedTime));
  // eslint-disable-next-line no-underscore-dangle
  const { days, hours, minutes } = timeDifference._data;
  const minutesWaited = `${minutes}m`;

  if (days !== 0) return `${days}d ${hours}h`;

  if (hours !== 0) return `${hours}h ${minutesWaited}`;

  return minutesWaited;
};

const checkedInTimeFormatter = (created) => moment(created).format('LT');

const telephoneNumberFormatter = (number) => {
  if (!number) return 'No number given';

  const areaCode = number.slice(0, 3);
  const exchangeCode = number.slice(3, 6);
  const lineNumber = number.slice(6);
  return `(${areaCode}) ${exchangeCode}-${lineNumber}`;
};

const ExpandedCard = ({ onClick, dispatch, handleSMSClick, position, selectedStatus }) => {
  const history = useHistory();

  const updateStatus = (status) => {
    dispatch({
      type: UPDATE_CUSTOMER_STATUS,
      waitlist_position_id: position.id,
      payload: { status },
    });
  };

  const nextStatus = selectedStatus === 'in_progress' ? 'complete' : 'in_progress';
  const nextStatusText = selectedStatus === 'in_progress'
    ? 'Move to complete'
    : 'Move to in progress';

  const onViewButtonClick = () => {
    const viewUrl = `/view-customer/${position.id}`;
    history.push(viewUrl);
  };

  return (
    <CustomerContainer onClick={onClick}>
      {categoriesIconSelector(position.categories)}
      <InfoContainer>
        <TopInfo>
          <Name>
            {`${customerNameFormatter(position.name)}`}
            <PartySize>{` (Party of ${position.party_size})`}</PartySize>
          </Name>
          <TagAndWaitTime>
            {hasTimeSensitiveCategory(position.categories) && <Tag text="Time Sensitive" />}
            <WaitTime>{waitTimeFormatter(position.created)}</WaitTime>
          </TagAndWaitTime>
        </TopInfo>
        <Categories>
          {categoriesFormatter(position.categories)}
        </Categories>
        {(position.optician_assigned || position.sales_advisor_assigned) && (
          <Assignee>
            {assigneeFormatter(
              position.categories,
              position.optician_assigned,
              position.sales_advisor_assigned,
            )}
            {' '}
            with
            {' '}
            {(
              position.optician_assigned_name || position.sales_advisor_assigned_name
            )}
          </Assignee>
        )}
        <PhoneAndCheckedIn>
          <InfoValue>
            <InfoLabel>Phone:</InfoLabel>
            {telephoneNumberFormatter(position.telephone)}
          </InfoValue>
          <InfoValue>
            <InfoLabel>Checked in:</InfoLabel>
            {checkedInTimeFormatter(position.created)}
          </InfoValue>
        </PhoneAndCheckedIn>
        <Notes>
          <InfoValue>
            <InfoLabel>Notes:</InfoLabel>
            {position.notes}
          </InfoValue>
        </Notes>
        {/* Call stopPropagation to prevent card collapsing on button click */}
        <Buttons onClick={e => e.stopPropagation()}>
          <CustomerItemButton onClick={onViewButtonClick}>
            View
          </CustomerItemButton>
          {
            selectedStatus === 'waiting' && (
              <SendSMSButton
                dispatch={dispatch}
                handleClick={handleSMSClick}
                id={position.id}
                smsSent={position.callback_sms_sent}
                waitTimeFormatter={waitTimeFormatter}
              />
            )
          }
          <CustomerItemButton onClick={() => updateStatus(nextStatus)}>
            {nextStatusText}
          </CustomerItemButton>
          <PositionOverflowMenu
            dispatch={dispatch}
            position={position}
            selectedStatus={selectedStatus}
          />
        </Buttons>
      </InfoContainer>
    </CustomerContainer>
  );
};

const CollapsedCard = ({ onClick, position }) => (
  <CustomerContainer onClick={onClick}>
    {categoriesIconSelector(position.categories)}
    <InfoContainer>
      <TopInfo>
        <Name>
          {`${customerNameFormatter(position.name)}`}
          <PartySize>{` (Party of ${position.party_size})`}</PartySize>
        </Name>
        <TagAndWaitTime>
          {hasTimeSensitiveCategory(position.categories) && <Tag text="Time Sensitive" />}
          <WaitTime>{waitTimeFormatter(position.created)}</WaitTime>
        </TagAndWaitTime>
      </TopInfo>
      <CompactCategories>
        {categoriesFormatter(position.categories)}
      </CompactCategories>
      {(position.optician_assigned || position.sales_advisor_assigned) && (
        <Assignee>
          {assigneeFormatter(
            position.categories,
            position.optician_assigned,
            position.sales_advisor_assigned,
          )}
          {' '}
          with
          {' '}
          {(
            position.optician_assigned_name || position.sales_advisor_assigned_name
          )}
        </Assignee>
      )}
    </InfoContainer>
  </CustomerContainer>
);

const CustomerItem = ({
  expanded,
  setExpandedPositionId,
  dispatch,
  handleSMSClick,
  position,
  selectedStatus,
}) => {
  if (expanded) {
    return (
      <ExpandedCard
        onClick={() => setExpandedPositionId(null)}
        dispatch={dispatch}
        handleSMSClick={handleSMSClick}
        position={position}
        selectedStatus={selectedStatus}
      />
    );
  }

  return (
    <CollapsedCard
      onClick={() => setExpandedPositionId(position.id)}
      dispatch={dispatch}
      handleSMSClick={handleSMSClick}
      position={position}
      selectedStatus={selectedStatus}
    />
  );
};

const select = (state) => ({
  error: state.waitlist.error.remove_customer,
  removed: state.waitlist.removed,
  removing: state.waitlist.removing,
});

export default connect(select)(CustomerItem);
