// used naming from design and https://chir.ag/projects/name-that-color/
export const colors = {
  allports: '#006EA5',
  alto: '#D2D2D2',
  blue: '#00A2E1',
  lightBlue: '#D8EFF6',
  blueHover: '#3DBDEE',
  charcoal: '#414B56',
  darkBlue: '#0089BF',
  gray: '#929292',
  grayChateau: '#A1A4A6',
  grayBorder: '#e4e6e8',
  red: '#D6003C',
  redHover: '#E4405D',
  redPressed: '#BE0035',
  silk: '#F8F8F8',
  silver: '#E1E5E6',
  silverChalice: '#A3A3A3',
  smoke: '#A1A4A6',
  stone: '#676F78',
  tundora: '#474747',
  white: '#FFFFFF',
};
