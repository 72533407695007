export const FETCH_APPOINTMENTS = 'FETCH_APPOINTMENTS';
export const FETCH_FACILITY_EXAM_SERVICES = 'FETCH_FACILITY_EXAM_SERVICES';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';

export const FETCH_APPOINTMENTS_SUCCESS = 'FETCH_APPOINTMENTS_SUCCESS';
export const FETCH_APPOINTMENTS_FAILURE = 'FETCH_APPOINTMENTS_FAILURE';

export const FETCH_FACILITY_EXAM_SERVICES_SUCCESS = 'FETCH_FACILITY_EXAM_SERVICES_SUCCESS';
export const FETCH_FACILITY_EXAM_SERVICES_FAILURE = 'FETCH_FACILITY_EXAM_SERVICES_FAILURE';

export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAILURE = 'UPDATE_APPOINTMENT_FAILURE';

export const REQUEST_APPOINTMENT_MODIFICATION = 'REQUEST_APPOINTMENT_MODIFICATION';

export const requestAppointmentModificationAction = (
  payload: { modifyAppointmentUrl: string },
) => ({
  type: REQUEST_APPOINTMENT_MODIFICATION,
  payload,
});
